define("discourse/plugins/discourse-breadcrumbify-topic-title-plugin/discourse/initializers/add-hierarchy-class-to-body", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "add-hierarchy-class",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        api.onPageChange((url, title) => {
          const body = document.body;
          const controller = api.container.lookup("controller:topic") || api.container.lookup("controller:private-message");
          if (controller && controller.model && Array.isArray(controller.model.breadcrumbs) && controller.model.breadcrumbs.length > 0) {
            body.classList.add("part-of-hierarchy");
          } else {
            body.classList.remove("part-of-hierarchy");
          }
        });
      });
    }
  };
});