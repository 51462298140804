define("discourse/plugins/discourse-breadcrumbify-topic-title-plugin/discourse/connectors/topic-title/topic-children", ["exports", "@glimmer/component", "@ember/service", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BreadcrumbifiedTopicTitle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get children() {
      return this.model.children;
    }
    get model() {
      return this.args.outletArgs.model;
    }
    get childrenPresent() {
      return this.model.children.length > 0;
    }
    hasUpdates(topic1) {
      // Convert the timestamp strings to Date objects
      const updatedDate1 = new Date(topic1.updated_at);
      const lastVisitedDate1 = topic1.last_visited_at ? new Date(topic1.last_visited_at) : undefined;
      // If lastVisitedDate is undefined, return true
      if (!lastVisitedDate1) {
        return "has-updates";
      }
      // Compare the two dates
      return updatedDate1 > lastVisitedDate1 ? "has-updates" : "";
    }
    cleanTitle(title1) {
      // Split the input string by the '>' symbol
      const segments1 = title1.split(">");
      // Trim any leading or trailing whitespace from each segment
      const trimmedSegments1 = segments1.map(segment1 => segment1.trim());
      // Return the last segment
      return trimmedSegments1[trimmedSegments1.length - 1];
    }
    getInlineStyle(_this1, child1) {
      // console.log(_this.currentUser.groups);
      // console.log(_this.site.groups);
      // console.log(child.access_through_groups);
      switch (child1.archetype) {
        case "regular":
          return `border-left: 4px solid var(--category-${child1.category_id}-color);`;
        case "private_message":
          const matchingGroups1 = _this1.getMatchingGroups(child1.access_through_groups, _this1.site.groups);
          if (matchingGroups1[0]) {
            console.log(matchingGroups1);
            if (matchingGroups1[0].flair_url === null) {
              return `border-left: 25px solid #${matchingGroups1[0].flair_bg_color}; padding-left: 10px;`;
            } else {
              return `background-image: url(${matchingGroups1[0].flair_url}); padding-left: 34px;`;
            }
          } else {
            // individually adressed in PM
          }
      }
    }
    getMatchingGroups(accessThroughGroups1, siteGroups1) {
      // Create a Set from accessThroughGroups for faster lookup
      const accessGroupSet1 = new Set(accessThroughGroups1);
      // Filter the siteGroups to include only those with IDs in accessGroupSet
      return siteGroups1.filter(group1 => accessGroupSet1.has(group1.id));
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.childrenPresent}}
          <ul class="topic-children">
            {{#each this.children as |child index|}}
              <li
                class="{{if child.bookmarked 'bookmarked'}}
                  {{this.hasUpdates child}}"
              >
                <a
                  class="topic-child-link"
                  href="/t/{{child.slug}}/{{child.id}}"
                  style="{{this.getInlineStyle this child}}"
                >
                  {{this.cleanTitle child.title}}
                  {{#if child.bookmarked}}
                    <svg
                      class="fa d-icon d-icon-bookmark svg-icon svg-string"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#bookmark"></use>
                    </svg>
                  {{/if}}
                </a>
              </li>
            {{/each}}
          </ul>
        {{/if}}
      
    */
    {
      "id": "ahj/Qy3O",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"childrenPresent\"]],[[[1,\"      \"],[10,\"ul\"],[14,0,\"topic-children\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"children\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[15,0,[29,[[52,[30,1,[\"bookmarked\"]],\"bookmarked\"],\"\\n              \",[28,[30,0,[\"hasUpdates\"]],[[30,1]],null]]]],[12],[1,\"\\n            \"],[10,3],[14,0,\"topic-child-link\"],[15,6,[29,[\"/t/\",[30,1,[\"slug\"]],\"/\",[30,1,[\"id\"]]]]],[15,5,[28,[30,0,[\"getInlineStyle\"]],[[30,0],[30,1]],null]],[12],[1,\"\\n              \"],[1,[28,[30,0,[\"cleanTitle\"]],[[30,1,[\"title\"]]],null]],[1,\"\\n\"],[41,[30,1,[\"bookmarked\"]],[[[1,\"                \"],[10,\"svg\"],[14,0,\"fa d-icon d-icon-bookmark svg-icon svg-string\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n                  \"],[10,\"use\"],[14,6,\"#bookmark\"],[12],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"child\",\"index\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-breadcrumbify-topic-title-plugin/discourse/connectors/topic-title/topic-children.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BreadcrumbifiedTopicTitle;
});